import React from "react";
import './App.scss';

function App() {
  return (
    <div className="coming-soon">
      <div className="content">
        <h1>Project Coming Soon</h1>
        <p>We're working hard to bring you something amazing! Stay tuned for updates.</p>
        <div className="timer">
          <p>Launching Soon...</p>
        </div>
        {/* <div className="subscribe">
          <input type="email" placeholder="Enter your email" />
          <button>Notify Me</button>
        </div> */}
      </div>
    </div>
  );
}

export default App;
